import React, { useState } from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from 'styled-components';
import { MinimizeSharp, Add } from '@material-ui/icons';
import { Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
export default function EmployeeData(props) {
    const { t, i18n } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [usersData, setUsersData] = useState([{ id: 1, name: '', age: '', }]);
    const [showModal, setShowModal] = useState(false);
    const addNewUser = () => {
        setUsersData([...usersData, { id: usersData.length + 1, name: '', age: '' }]);
    }
    const removeUser = (index) => {
        let currentUsers = usersData;
        currentUsers = currentUsers.filter((user, i) => i !== index);
        setUsersData(currentUsers);
    }
    const typeing = (e, index, name) => {
        let currentUsers = usersData;
        currentUsers[index][name] = e.target.value;
        setUsersData(currentUsers);
    }
    const onSubmit = () => {
        props.onSubmit(JSON.stringify(usersData));
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 25, maxWidth: '90%',borderRadius: 15 }} className={props.name}>
            <BoldText className="mb-3 mt-3">4. {t('Enter your employees data (Name, Date of birth)')}</BoldText>
            <Card style={{ marginBottom: 25 }}>
                <Row style={{ padding: 10 }}>
                    <Col xs={6} md={6} lg={6}><Text>{t("Name (First, 2nd, third)")}</Text></Col>
                    <Col xs={6} md={6} lg={6}><Text>{t('Date of birth')}</Text></Col>
                </Row>
                {usersData.map((item, k) => (
                    <Row key={k} style={{ padding: 10, alignItems: 'center' }} >
                        {/* <Col xs={1} md={1} lg={1}><IndexDiv>{`#${k + 1}`}</IndexDiv></Col> */}
                        {/* <Col xs={1} md={1} lg={1}>-</Col> */}
                        <Col xs={5} md={5} lg={5}>
                            <Input
                                id={`name-${k}`}
                                {...register(`name-${k}`, {
                                    required: "required",
                                    minLength: {
                                        value: 10,
                                        message: "min length is 10"
                                    }
                                })}
                                type="text"
                                placeholder={t("Name (First, 2nd, third)")}
                                onChange={(e) => { typeing(e, k, 'name'); props.setRequestName(e.target.value); }}
                            />
                            {errors[`name-${k}`] && <span role="alert">{errors[`name-${k}`].message}</span>}
                        </Col>
                        <Col xs={5} md={5} lg={5}>
                            <Input
                                id={`employee_dob-${k}`}
                                {...register(`employee_dob-${k}`, {
                                    required: "required"
                                })}
                                type="text"
                                placeholder={t("Date of birth")}
                                onChange={(e) => typeing(e, k, 'age')}
                            />
                            {errors[`employee_dob-${k}`] && <span role="alert">{errors[`employee_dob-${k}`].message}</span>}
                        </Col>
                        <Col xs={1} md={1} lg={1}><MinusButton onClick={() => removeUser(k)}><MinimizeSharp /></MinusButton></Col>
                    </Row>
                ))}
                <AddButton onClick={addNewUser}>{t('Add')} <Add /></AddButton>
                <Button type='submit' style={{ width: '30%', alignSelf: 'center' }}>{props.loader ? <Spinner animation="border" /> : t('Submit')}</Button>
            </Card>
            <Modal
                show={props.showModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => props.closeModal()}
                size="lg"
            >
                <Modal.Body>
                    <SubContainer>
                        <Text>{t('Thanks for contacting with us,Our team will get in touch shortly.')}</Text>
                    </SubContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => props.closeModal()}>{t('Okay')}</Button>
                </Modal.Footer>
            </Modal>
        </form>
    )
}

const BoldText = styled.div`
    font-size: 18px;
    font-weight: 600;
    padding: 0 20px 0 0
`;
const Input = styled.input`
    background-color: rgba(0,0,0,.05);
`;
const Text = styled.div`
    font-size: 20px;
    padding: 0 20px 0 0;
    color: #666666;
`;
const IndexDiv = styled.div`
    background-color: rgb(81, 92, 221);
    color: white;
    text-align:center;
    padding:5px;
    border-radius: 5px;
    align-items:center;
    justify-content:center;
`;
const AddButton = styled.button`
    background-color: #515cdd;
    color: #FFF;
    max-width: 80px;
    text-align:center;
    padding:5px;
    border: none;
    border-radius: 5px;
`;
const MinusButton = styled.button`
    font-size: 10px;
    color: #FFF;
    border: none;
    border-radius: 3px;
    padding: 5px;
    outline: 0;
    cursor: pointer;
    background: #ff4200;

`;
const Button = styled.button`
 font-size: 16px;
 color: #FFF;
 border: 0.5px solid #515cdd;
 padding: 5px 15px;
 border-radius: 15px;
 outline: 0;
 margin: 10px 0px;
 cursor: pointer;
 background: #515cdd;
`;
const SubContainer = styled.div`
    padding:10px 7px 5px 7px;
`;