import React, { useEffect, useState } from 'react'
import { Card, Row, InputGroup, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from 'styled-components';
import CardMasterCard from '../assets/Card-MasterCard.svg';
import CardVisa from '../assets/Card-Visa.svg';
import { CreditCard, Help } from '@material-ui/icons';
export default function PaymentForm(props) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control
    } = useForm();
    const [chronicCondition, setChronicCondition] = useState(false);
    const [usersData, setUsersData] = useState([{ id: 1, name: '', age: '', }]);
    const addNewUser = () => {
        setUsersData([...usersData, { id: usersData.length + 1, name: '', age: '' }]);
    }
    const removeUser = (index) => {
        let currentUsers = usersData;
        currentUsers = currentUsers.filter((user, i) => i !== index);
        setUsersData(currentUsers);
    }
    return (
        <Container >
            <BoldText>Add your payment data</BoldText>
            <Card className="mb-4">
                <Text>Amount to be paid is3763.21 EGP</Text>
                <iframe className="vh-100" src={'https://secure-egypt.paytabs.com/payment/request'} width={'100%'}/>         
            </Card>
        </Container>
    )
}

const BoldText = styled.div`
    font-size: 18px;
    font-weight: bold;
    padding: 0 20px 0 0
`;
const Text = styled.div`
    font-size: 20px;
    padding: 20px;
    color: #666666;

`;
const Button = styled.button`
 font-size: 20px;
 color: white;
 align-self: center;
 border: 0.5px solid #e6ecf1;
 padding: 5px 15px;
 border-radius: 5px;
 outline: 0;
 margin: 10px 0px;
 cursor: pointer;
 transition: ease background-color 250ms;
 background: linear-gradient(to top,  #3f6bc2 0%,#a2b7e5 100%);
 &:hover {
     background: linear-gradient(to bottom,  #3f6bc2 0%,#a2b7e5 100%)
 }
`;