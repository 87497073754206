import React, { useState } from "react";
import 'react-phone-input-2/lib/style.css';
import Mastercard from '../../assets/Mastercard-icon.svg';
import ValuIcon from '../../assets/valu-icon.png';
import VisaIcon from '../../assets/visa-icon.png';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const BoldLine = styled.div`
    display:flex;
    width:100%;
    height:2px;
    background-color:#b9b9b9;
    margin-bottom:20px;
`;
export default function Footer() {
    const { t, i18n } = useTranslation();
    return (
        <footer className="text-white" style={{backgroundColor:'#515cdd', fontFamily: 'Cairo'}}>
            <div className="p-4">
                <section className="">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 mb-4 mb-md-0">
                            <h5 className="text-decoration-underline">{t('Contact us')}</h5>

                            <ul className="list-unstyled mb-0">
                                <li>
                                    {t('Tel')}: <a style={{ textDecoration: 'underline', color: '#FFF', direction: "ltr" }} href="tel:+201015111776">+201015111776</a>
                                </li>
                                <li>
                                    <div className="contact_item footer-text footer-item footer-first-row-item">{t('Email')}: info@amanleek.com</div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 mb-4 mb-md-0">
                            <h5 className="text-decoration-underline">{t('Company')}</h5>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <a href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}about-amanleek/`} className="text-white text-decoration-none">{t('About Amanleek')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}terms-and-conditions/`} className="text-white text-decoration-none">{t('Terms & Conditions')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}privacy-policy/`} className="text-white text-decoration-none">{t('Privacy Policy')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}sitemap/`} className="text-white text-decoration-none">{t('Sitemap')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}insurance-companies-in-egypt/`} className="text-white text-decoration-none">{t('Insurance Companies')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}vacancies/`} className="text-white text-decoration-none">{t('Job Vacancies - We are Hiring !')}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 mb-4 mb-md-0">
                            <h5 className="text-decoration-underline">{t('Follow Amanleek')}</h5>
                            <a className="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/amanleek.insurance/" role="button"
                            ><i className="fa fa-facebook"></i
                            ></a>
                            <a className="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/amanleek/" role="button"
                            ><i className="fa fa-instagram"></i
                            ></a>
                            <a className="btn btn-outline-light btn-floating m-1" href="https://twitter.com/amanleek/" role="button"
                            ><i className="fa fa-twitter"></i
                            ></a>
                            <a className="btn btn-outline-light btn-floating m-1" href="https://www.youtube.com/channel/UCRqZ9FotKGCXhwoJogRLA0A" role="button"
                            ><i className="fa fa-youtube"></i
                            ></a>
                            <a className="btn btn-outline-light btn-floating m-1" href="https://www.linkedin.com/company/amanleek/" role="button"
                            ><i className="fa fa-linkedin"></i
                            ></a>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <h5 className="text-decoration-underline">{t('We accept')}</h5>
                    <div>
                        <img src={Mastercard} alt="Mastercard" className="img-fluid" style={{margin:5}}/>
                        <img src={VisaIcon} alt="Visa" className="img-fluid" style={{margin:5}}/>
                        <img src={ValuIcon} alt="Valu" className="img-fluid" style={{margin:5}}/>
                    </div>
                </section>
                
                <BoldLine />
                <section className="mb-4">
                    <p>{t("In case of having a complaint you have the right to escalate it to the Financial Regulatory Authority's head office or through their website www.fra.gov.eg")}</p>
                    <p>{t("Amanleek.com is licenced by the Financial Regulatory Authority on 5/11/2019")}</p>
                </section>
            </div>
            <div className='text-center p-3' style={{direction: "ltr"}}>
                © 2022 Amanleek. All Rights Reserved.
            </div>

        </footer>
    );
}
