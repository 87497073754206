import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
  ar: {
    translation: {
      "العربية": "English",
      "Contact us":"اتصل بنا",
      "Blog":"مقالات",
      "FAQ":"الأسئلة الشائعة",
      "Insurance products":"أنواع التأمين",
      "About us":"عن أمان ليك",
      "Homepage":"الرئيسية",
      "Car insurance":"تأمين السيارات",
      "Travel insurance":"تأمين السفر",
      "Life insurance":"تأمين الحياة",
      "Property insurance":"تأمين الممتلكات",
      "Get health insurance for your employees in a minute":"احصل على عروض التأمين الطبي للموظفين في دقيقة",
      "Tel":"تليفون",
      "Email":"الإيميل",
      "Company":"الشركة",
      "About Amanleek":"عن أمان ليك",
      "Terms & Conditions":"الشروط و الاحكام",
      "Privacy Policy":"سياسة الخصوصية",
      "Sitemap":"خريطة الموقع",
      "Insurance Companies":"شركات التأمين",
      "Job Vacancies - We are Hiring !":"وظائف - لدينا فرص عمل !",
      "Follow Amanleek":"تابع أمان ليك",
      "We accept":"طرق الدفع",
      "In case of having a complaint you have the right to escalate it to the Financial Regulatory Authority's head office or through their website www.fra.gov.eg <br/>Amanleek.com is licenced by the Financial Regulatory Authority on 5/11/2019":"في حالة عدم رضائك عن خدمة أمان ليك يحق لك تصعيد الشكوى الى مقر الهيئة العامة للرقابة المالية أو عبر موقعها الإلكتروني www.fra.gov.eg <br/> موقع أمان ليك حاصل على موافقة الهيئة العامة للرقابة المالية بتاريخ 5/11/2019",
      "Get health insurance for your employees through us in 5 simple steps":"احصل على تأمين صحي لموظفيك من خلالنا في 5 خطوات بسيطة",
      'Enter your company name and employee numbers then click the "calculate offers" button,':'أدخل اسم شركتك وعدد الموظفين ، ثم انقر فوق الزر "إظهر العروض" ،',
      'enter your name and phone number then click the "go to offers" button':'أدخل اسمك ورقم هاتفك ثم انقر على زر "الانتقال إلى العروض"',
      "Compare between offers from different insurers and ask for help if you can't decide":"قارن بين أفضل العروض - ولو في حاجة مش واضحة ومحتاج تفهمها تواصل معانا وهنساعدك",
      "Choose the most suitable plan then upload your commercial register, tax card and enter your current address":"اختار البرنامج المناسب ليك وأرفع صورة سجلك التجاري وبطاقتك الضريبية وأدخل عنوانك الحالي",
      "Enter your employees data (Name, Date of birth)":"أدخل بيانات موظفيك (الاسم ، تاريخ الميلاد)",
      "Add your payment data":"أدخل بيانات الدفع الخاصة بك",
      "1. Enter the required data":"1. أدخل البيانات المطلوبة",
      "Enter your company name, employee numbers, dependents numbers & average age":"أدخل اسم شركتك وعدد الموظفين و عدد أفراد الأسر وأعمارهم",
      "Company name":"اسم الشركة",
      "Employees number":"عدد الموظفين",
      "Dependents number ( optional )":"عدد أفراد الأسر ( اختياري )",
      "Average age":"متوسط أعمار الموظفين",
      "Dependents number":"عدد أفراد الأسر",
      "select coverage range":"اختر التغطية للفرد",
      "Enter your name and phone number":"أدخل اسمك ورقم هاتفك",
      "Phone Number":"رقم الهاتف",
      "Name":"الاسم",
      "We have found":"وجدنا",
      "offers for your company":"عروض لشركتك",
      "Calculate offers":"إظهر العروض",
      "Go to offers":"الانتقال إلى العروض",
      "2. Compare and Choose the most suitable plan":"2. قارن بين أفضل العروض واختار البرنامج المناسب ليك",
      "plan name:":"البرنامج:",
      "Life Coverage Ceiling:":"التأمين على الحياة:",
      "Health coverage ceiling:":"التأمين الطبي:",
      "Room & Board:":"درجة الإقامة:",
      "Inpatient services:":"العلاج الداخلي بالمستشفيات:",
      "outpartient services:":"خدمات العيادات الخارجية:",
      "Dental Coverage:":"تغطية الاسنان:",
      "Optical Coverage:":"تغطية البصريات:",
      "Chronic & pre-existing:":"الأمراض المزمنة السابقة عن التعاقد:",
      "Outside network service:":"العلاج خارج الشبكة الطبية:",
      "Additional benefits:":"مزايا إضافية:",
      "Premium per member:":"متوسط الاشتراك السنوي للفرد:",
      "Total annual premiums:":"إجمالي الاشتراكات السنوية:",
      "3. Upload your commercial register, tax card and enter your current address":"3. أرفع صورة سجلك التجاري وبطاقتك الضريبية وأدخل عنوانك الحالي",
      "Commercial register":"السجل التجاري",
      "Tax card":"البطاقة الضريبية",
      "Current address":"العنوان الحالي",
      "Does any of the members suffer from chronic conditions ?":"ھل یعاني أي موظف في الشركة من أمراض مزمنة حالیا ؟",
      "Yes":"نعم",
      "No":"لا",
      "Next":"التالي",
      "Due to having one member or more with chronic conditions, our team will get in touch shortly to support with the exact pricing":"نظرا لوجود عدد من الموظفین لدیھم أمراض مزمنة سیقوم فریقنا بالاتصال بك في أقرب وقت لإعادة التسعیر",
      "Confirm":"تاكيد",
      "Back to results":"الرجوع إلى النتائج",
      "Name (First, 2nd, third)":"الاسم (الاول، الثاني، الثالث)",
      "Date of birth":"تاريخ الميلاد",
      "Thanks for contacting with us,Our team will get in touch shortly.":"شكرا لتواصلك معنا، سيتواصل فريقنا معك في اسرع وقت ممكن",
      "Okay":"تم",
      "Add":"إضافة",
      "Submit":"تاكيد",
      "Get this offer":"احصل على العرض",
      "Coverage range":"التغطية للفرد",
      "Medications":"الادوية الموصوفة",
      "Physiotherapy":"العلاج الطبيعي",
      "Select coverage range for person per year":"برجاء اختيار التغطية السنوية للفرد الواحد",
      "In case of having a complaint you have the right to escalate it to the Financial Regulatory Authority's head office or through their website www.fra.gov.eg":"في حالة عدم رضائك عن خدمة أمان ليك يحق لك تصعيد الشكوى الى مقر الهيئة العامة للرقابة المالية أو عبر موقعها الإلكتروني www.fra.gov.eg",
      "Amanleek.com is licenced by the Financial Regulatory Authority on 5/11/2019":"موقع أمان ليك حاصل على موافقة الهيئة العامة للرقابة المالية بتاريخ 5/11/2019",
      "Please select coverage range for person per year":"برجاء اختيار معدل التغطية السنوية للفرد الواحد"
    }
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
