import React, { Fragment } from "react";
import { Navbar, Container, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import amanleeklogo from '../../assets/amanleek-logo.png';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { red } from "@material-ui/core/colors";

const CustomNavbar = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    document.body.dir = i18n.language == 'ar' ? 'rtl' : 'ltr';
    const changeLanguage = lng => {
        i18n.changeLanguage(lng)
        document.body.dir = lng =='ar' ? 'rtl' : 'ltr';
        theme.direction = lng =='ar' ? 'rtl' : 'ltr';
    };
    const [show, setShow] = React.useState(false);
    const showDropdown = () => setShow(true);
    const hideDropdown = () => setShow(false);
    return (
            <Navbar expand="lg" className="fixed-top" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)',height: 50, paddingRight:0, }}>
                <Container fluid>
                    <Navbar.Brand href="https://www.amanleek.com" ><img src={amanleeklogo} style={{ flex: 0.4, height:20 }} alt="amanleek logo" /> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" style={{flex:0.1, flexGrow: 0}}/>
                    <Navbar.Collapse id="navbarScroll" style={{ flexGrow: 0, fontFamily: 'Cairo'}}>
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            navbarScroll
                        >
                            <Nav.Link>{t('Homepage')}</Nav.Link>
                            <Nav.Link href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}about-amanleek/`}>{t('About us')}</Nav.Link>
                            <NavDropdown
                                title={t("Insurance products")}

                                id="navbarScrollingDropdown"
                                show={show}
                                onMouseEnter={showDropdown}
                                onMouseLeave={hideDropdown}
                            >
                                <NavDropdown.Item href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}insurance-products/car-insurance/`}>{t('Car insurance')}</NavDropdown.Item>
                                <NavDropdown.Item href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}insurance-products/travel-insurance-health/`}>{t('Travel insurance')}</NavDropdown.Item>
                                <NavDropdown.Item href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}insurance-products/life-insurance/`}>{t('Life insurance')}</NavDropdown.Item>
                                <NavDropdown.Item href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}insurance-products/property-insurance/`}>{t('Property insurance')}</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}faq/`}>{t('FAQ')}</Nav.Link>
                            <Nav.Link href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}category/insurance-blog/`}>{t('Blog')}</Nav.Link>
                            <Nav.Link href={`https://www.amanleek.com/${i18n.language=='en'?'en/':''}contact/`}>{t('Contact us')}</Nav.Link>
                            <Nav.Link onClick={() => changeLanguage(i18n.language == 'ar' ? 'en' : 'ar')}>{t('العربية')}</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    )
}
export default CustomNavbar
