import { useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import SMEFormPage from './pages/SMEFormPage';
import Navbar from './components/shared/Navbar';
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import Footer from './components/shared/Footer';
function App() {
  mixpanel.track( '[sme v2] visit loading landing page' );
  useEffect(() => { 
    mixpanel.track( '[sme v2] visit fully loaded landing page' );
   }, []);
   const url = new URL(window.location.href);
   const params = new URLSearchParams( url.search );
   const utmSource = params.get('utm_source');
   const utmDomain = params.get('utm_domain');
 
   mixpanel.register_once({
     "[sme v2] utm source": utmSource,
     "[sme v2] utm domain": utmDomain,
   });
 
 
  const { t, i18n } = useTranslation();
  return (
    <Router>
      <Navbar />
      <h1 style={{color:'#515cdd', fontWeight: 700, marginTop: 80}}>{t('Get health insurance for your employees in a minute')}</h1>
      <Routes>
          <Route exact path="" element={<SMEFormPage/>} />
          <Route exact path="/about" element={<SMEFormPage/>} />
          <Route exact path="/users" element={<SMEFormPage/>} />
        </Routes>
        <Footer />
    </Router>
  );
}
export default App;
