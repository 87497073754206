module.exports = {
	// "leadUrl":  'https://4ownf8zwre.execute-api.eu-west-3.amazonaws.com/cloud/lead',
	leadUrl: `https://koh21ncbjb.execute-api.eu-west-3.amazonaws.com/dev/lead`,
	// "smeRequestUrl": 'https://4ownf8zwre.execute-api.eu-west-3.amazonaws.com/cloud/sme/request',
	smeRequestUrl: `https://koh21ncbjb.execute-api.eu-west-3.amazonaws.com/dev/sme/request`,
	// "smeGetPlansUrl": 'https://4ownf8zwre.execute-api.eu-west-3.amazonaws.com/cloud/sme/get-plans',
	smeGetPlansUrl: `https://koh21ncbjb.execute-api.eu-west-3.amazonaws.com/dev/sme/get-plans`,
	// "uploadImage":
	// 'https://3s6oqeuja2.execute-api.eu-west-3.amazonaws.com/dev/upload-image',
	// "uploadImage":'https://4ownf8zwre.execute-api.eu-west-3.amazonaws.com/cloud/upload-image',
	uploadImage: `https://koh21ncbjb.execute-api.eu-west-3.amazonaws.com/dev/upload-image`,
	// createAuthUser:
	// 	'https://4ownf8zwre.execute-api.eu-west-3.amazonaws.com/cloud/user/create',
	createAuthUser:
		'https://koh21ncbjb.execute-api.eu-west-3.amazonaws.com/dev/user/create',
	// refreshToken:
	// 	'https://4ownf8zwre.execute-api.eu-west-3.amazonaws.com/cloud/user/refresh-access-token',
	refreshToken: `https://koh21ncbjb.execute-api.eu-west-3.amazonaws.com/dev/user/refresh-access-token`,
};
