import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import axios from 'axios';
import Dynamic from "../components/Dynamic";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import CommercialForm from "../components/CommercialForm";
import EmployeeData from "../components/EmployeeData";
import PaymentForm from "../components/PaymentForm";
import ApisFile from "../ApisFile";
import { createPaymentPage, createRefreshToken, requestRefreshToken, handleSmeRequest, createLead } from "../Functions";
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser'


export default function SMEFormPage() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const { t, i18n } = useTranslation();
    const [companyName, setcompanyName] = useState(null);
    const [employeeNumber, setEmployeeNumber] = useState(0);
    const [dependentsNumber, setDependentsNumber] = useState(0);
    const [averageAge, setAverageAge] = useState(0);
    const [annualCoverage, setAnnualCoverage] = useState(0);
    const [name, setName] = useState(null);
    const [userAuthTokens, setUserAuthTokens] = useState({ RefreshToken: null });
    const [leadId, setLeadId] = useState(null);
    const [requestId, setRequestId] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState();
    const [plansData, setPlansData] = useState([]);
    const [showmobileForm, setShowmobileForm] = useState(false);
    const [showPlans, setShowPlans] = useState(false);
    const [showregData, setShowregData] = useState(false);
    const [showEmployeeData, setShowEmployeeData] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [choosenPlan, setChoosenPlan] = useState({});
    const [payLink, setPayLink] = useState(null);
    const [requestName, setRequestName] = useState(null);
    const [offersButtonLoader, setOffersButtonLoader] = useState(false);
    const [showOffersButtonLoader, setShowOffersButtonLoader] = useState(false);
    const [selectPlanButtonLoader, setSelectPlanButtonLoader] = useState(false);
    const [companyPaperLoader, setCompanyPaperLoader] = useState(false);
    const [submitEmployeeDataLoader, setsubmitEmployeeDataLoader] = useState(false);
    const [thanksModal, setThanksModal] = useState(false);

    const submitCompanyData = async () => {
        mixpanel.register({
            '[sme v2] company name': companyName,
            '[sme v2] employees number': employeeNumber,
            '[sme v2] dependents number': dependentsNumber,
            '[sme v2] coverage level': annualCoverage,
            '[sme v2] average age': averageAge,
        });  
        setOffersButtonLoader(true);
        console.log('submitCompanyData', employeeNumber,typeof (employeeNumber), `${ApisFile.smeGetPlansUrl}`);
        await axios.post(`${ApisFile.smeGetPlansUrl}`, {
            "name": companyName,
            "dependents": dependentsNumber,
            "employeeNumberRange": employeeNumber,
            "maximumCoverage": annualCoverage,
            "averageOrFullData": 0,
            "data": averageAge,
            "is_sme_sub_domain": true,
            "is_user_logged_in": false
        }, { headers: { "Accept-Language": i18n.language } }).then(res => {
            if (res) {
                console.log('response', res)
                if (res.data.cards) {
                    setPlansData(res.data.cards);
                    setShowmobileForm(true);
                    scroller.scrollTo("mobileForm", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                      });
                }
            }
            setOffersButtonLoader(false);
        }).catch((err) => {
            console.log(err.message);
            setOffersButtonLoader(false);});
        setShowmobileForm(true);
        scroller.scrollTo("mobileForm", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
          });
        console.log(companyName, employeeNumber, name, phoneNumber)
    };

    const submitPhone = async () => {
        mixpanel.register({
            '[sme v2] name': name,
            '[sme v2] phone number': phoneNumber,
        });  
        mixpanel.identify(phoneNumber);
        console.log(userAuthTokens, 'userAuthTokens')
        setShowOffersButtonLoader(true);
        let intEmp = parseInt(employeeNumber);
        let data = {
            name,
            "company_name": companyName,
            "average_age": averageAge,
            "dependents_number": dependentsNumber,
            "employees_number": intEmp,
            "annual_coverage": annualCoverage,
        }
        let currentTime = new Date();
        let todayTime = currentTime.getTime();
        if(!userAuthTokens.RefreshToken)
        createRefreshToken(phoneNumber, name)
            .then(async function (resp) {
                let expireDate = todayTime +resp.ExpiresIn;
                setUserAuthTokens({...userAuthTokens, expireDate, ...resp});
                await createLead(resp, name, phoneNumber)
                    .then(async function (leadData) {
                        setLeadId(leadData.lead_id)
                        await handleSmeRequest(resp, data, leadData.lead_id, requestId).then(async function (requestData) {
                            setRequestId(requestData.request_id)
                        })
                    })
                    setShowOffersButtonLoader(false);
                    setShowPlans(true);
                    scroller.scrollTo('plansData', {
                        duration: 800,
                        delay: 0,
                        smooth: 'easeInOutQuart'
                      })
            }).catch(function (error) {
                console.log('Requestfailed', error)
            });
        else if (parseInt(userAuthTokens.expireDate) < todayTime) {            
            requestRefreshToken(userAuthTokens.RefreshToken).then(async function (refreshTokenData) {
                let expireDate = todayTime +refreshTokenData.ExpiresIn;
                setUserAuthTokens({...userAuthTokens, expireDate, ...refreshTokenData });
                if(!leadId){
                    await createLead(refreshTokenData, name, phoneNumber)
                    .then(async function (leadData) {
                        setLeadId(leadData.lead_id)
                        await handleSmeRequest(refreshTokenData, data, leadData.lead_id, requestId).then(async function (requestData) {
                            setRequestId(requestData.request_id)
                        })
                        setShowOffersButtonLoader(false);
                        setShowPlans(true);
                        scroller.scrollTo('plansData', {
                            duration: 800,
                            delay: 0,
                            smooth: 'easeInOutQuart'
                          })
                    })
                }else{
                    await handleSmeRequest(refreshTokenData, data, leadId, requestId).then(async function (requestData) {
                        setRequestId(requestData.request_id)
                    })
                    setShowOffersButtonLoader(false);
                    setShowPlans(true);
                    scroller.scrollTo('plansData', {
                        duration: 800,
                        delay: 0,
                        smooth: 'easeInOutQuart'
                      })
                }
            })
        }else{
            if(!leadId){
                await createLead(userAuthTokens, name, phoneNumber)
                .then(async function (leadData) {
                    setLeadId(leadData.lead_id)
                    await handleSmeRequest(userAuthTokens, data, leadData.lead_id, requestId).then(async function (requestData) {
                        setRequestId(requestData.request_id)
                    })
                    setShowOffersButtonLoader(false);
                    setShowPlans(true);
                    scroller.scrollTo('plansData', {
                        duration: 800,
                        delay: 0,
                        smooth: 'easeInOutQuart'
                      })
                })
            }else{
                await handleSmeRequest(userAuthTokens, data, leadId, requestId).then(async function (requestData) {
                    setRequestId(requestData.request_id)
                })
                setShowOffersButtonLoader(false);
                setShowPlans(true);
                scroller.scrollTo('plansData', {
                    duration: 800,
                    delay: 0,
                    smooth: 'easeInOutQuart'
                  })
            } 
        } 
    };

    const selectSmePlans = async (plan) => {
        console.log(plan, 'selected plan')
        setSelectPlanButtonLoader(true)
        let data = {
            "plan_id": plan.ID.toString()
        }
        console.log(userAuthTokens, data, leadId, requestId)
        await handleSmeRequest(userAuthTokens, data, leadId, requestId);
        setShowregData(true);
        setChoosenPlan(plan);
        setSelectPlanButtonLoader(false);
        scroller.scrollTo("CommercialForm", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
          });
    };

    const submitEmployeeData = async (employees_data) => {        
        setsubmitEmployeeDataLoader(true)
        await handleSmeRequest(userAuthTokens, {employees_data}, leadId, requestId).then(async(res)=>{
            setsubmitEmployeeDataLoader(false)
            setThanksModal(true)            
        })
    };
    const updateCompanyPaper = async (data) => {
        await handleSmeRequest(userAuthTokens, data, leadId, requestId).then(async function (requestData) {
            setRequestId(requestData.request_id)
        })
    };
    return (
        <div style={{padding:20, fontFamily: 'Cairo'}}>
            <form onSubmit={handleSubmit(submitCompanyData)} style={{ marginBottom: 25 }}>
                <BoldText>{t('1. Enter the required data')}</BoldText>
                <section style={{ border: '1px solid rgba(0,0,0,.125)', padding: 20,borderRadius: 15,marginTop: 6 }}>
                    <label htmlFor="company_name" style={{textAlign:'inherit'}}>{t('Company name')}</label>
                    <Input
                        id="company_name"
                        aria-invalid={errors.company_name ? "true" : "false"}
                        {...register("company_name", {
                            required: "required",
                            onChange:(e)=>setcompanyName(e.target.value)
                        })}
                        type="text"
                        placeholder={t("Company name")}
                        style={{ backgroundColor: 'rgba(0,0,0,.05)' }}
                        
                        onBlur={()=>mixpanel.track('[sme v2] enter company name')}
                    
                    />
                    


                    {errors.company_name && <span role="alert">{errors.company_name.message}</span>}
                    <label htmlFor="employees_number" style={{textAlign:'inherit'}}>{t('Employees number')}</label>
                    <Input
                        id="employees_number"
                        aria-invalid={errors.passward ? "true" : "false"}
                        {...register("employees_number", {
                            required: "required",
                            onChange:(e)=>setEmployeeNumber(parseInt(e.target.value)),
                            min: {
                                value: 5,
                                message: "min is 5"
                            }
                        })}
                        type="number"
                        placeholder={t("Employees number")}
                        
                        
                        onBlur={()=>mixpanel.track('[sme v2] enter employees number')} 
                    />
                    {errors.employees_number && <span role="alert">{errors.employees_number.message}</span>}
                    <label htmlFor="dependents_number" style={{textAlign:'inherit'}}>{t('Dependents number ( optional )')}</label>
                    <Input
                        id="dependents_number"
                        aria-invalid={errors.passward ? "true" : "false"}
                        {...register("dependents_number", {
                            required: "required",
                            onChange:(e)=>setDependentsNumber(parseInt(e.target.value)),
                        })}
                        type="number"
                        placeholder={t("Dependents number")}
                        
                        onBlur={()=>mixpanel.track('[sme v2] enter dependents number')} 
                    />
                    {/* {errors.employees_number && <span role="alert">{errors.employees_number.message}</span>} */}

                    <label htmlFor="average_age" style={{textAlign:'inherit'}}>{t('Average age')}</label>
                    <Input
                        id="average_age"
                        aria-invalid={errors.passward ? "true" : "false"}
                        {...register("average_age", {
                            required: "required",
                            onChange:(e)=>setAverageAge(parseInt(e.target.value)),
                        })}
                        type="number"
                        placeholder={t("Average age")}
                        
                        onBlur={()=>mixpanel.track('[sme v2] enter average age')} 

                    />
                    {errors.employees_number && <span role="alert">{errors.employees_number.message}</span>}


                    <label htmlFor="covearge_range" style={{textAlign:'inherit'}}>{t('Coverage range')}</label>
                    <Form.Select size="lg" aria-label="Default select example" 
                        {...register("covearge_range", {
                            required: "required",
                            onChange:(e)=>setAnnualCoverage(parseInt(e.target.value)),
                        })}
                    >
                        <option>{t('Please select coverage range for person per year')}</option>
                        <option value="30000">25k - 30k</option>
                        <option value="75000">50k - 75k</option>
                        <option value="15000">100k - 150k</option>
                                                
                        onBlur={()=>mixpanel.track('[sme v2] select coverage range')} 
                        

                    </Form.Select>
                    {errors.dependents_number && <span role="alert">{errors.employees_number.message}</span>}
                    <div className="d-flex justify-content-center">
                        <Button 
                            onClick={(e) => {
                                mixpanel.track('[sme v2] hit calculate offers');
                                } 
                            } 
                            type="submit">{offersButtonLoader?<Spinner animation="border" />:t('Calculate offers')}</Button>
                    </div>
                </section>
            </form>
            <div className="mobileForm">
            {showmobileForm &&
                <form onSubmit={handleSubmit(submitPhone)} style={{ marginBottom: 25 }}>
                    <section style={{ border: '1px solid rgba(0,0,0,.125)', padding: 20,borderRadius: 15 }}>
                        <BoldText style={{ marginTop: 25 }}>{`${t('We have found')} ${plansData.length} ${t('offers for your company')},`}<br />{t('Enter your name and phone number')}</BoldText>
                        <label htmlFor="name" style={{textAlign:'inherit'}}>{t('Name')}</label>
                        <Input
                            id="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            {...register("name", {
                                required: "required",
                                onChange: (e)=>setName(e.target.value)
                            })}
                            type="text"
                            placeholder={t("Name")}
                        
                        
                            onBlur={()=>mixpanel.track('sme v2] enter name')} 
                        />
                        {errors.name && <span role="alert">{errors.name.message}</span>}
                        <label htmlFor="Phone_number" style={{textAlign:'inherit'}}>{t('Phone Number')}</label>
                        <PhoneInput
                            country={'eg'}
                            value={phoneNumber}
                            

                            onChange={(value)=>setPhoneNumber(value)}
                        
                            onBlur={()=>mixpanel.track('[sme v2] enter phone number')} 


                            containerStyle={{
                                border: 'none',
                                backgroundColor: 'rgba(0,0,0,.05)',
                                borderRadius: 5,
                                padding: 5,
                                direction: 'ltr'
                            }}
                            inputStyle={{
                                width: '100%',
                                border: 'none',
                                backgroundColor: 'transparent',
                            }}
                            buttonStyle={{
                                border: 'none',
                            }}
                            isValid={(value, country) => {
                                if (value.match(/01[0125][0-9]{8}$/)) {
                                    return true;
                                } else if (value.length > 10) {
                                    return 'Invalid value: ' + value + ', ' + country.name;
                                }
                            }}
                        
                        />
                        <div className="d-flex justify-content-center">
                            <Button onClick={() =>mixpanel.track('[sme v2] hit get offers')
                            } type="submit">{showOffersButtonLoader?<Spinner animation="border" />:t('Go to offers')}</Button>
                        </div>
                    </section>
                </form>
            }
            </div>
            <div className="plansData">
            {showPlans &&
                <Dynamic 
                name= "plansData"
                data={plansData} 
                selectPlan={(plan) => {
                    mixpanel.track('[sme v2] hit get this offer');
                    return selectSmePlans(plan)
                    } 
                }
                loader={selectPlanButtonLoader}
                />
            }
            </div>
            <div className="CommercialForm">
            {showregData &&
                <CommercialForm
                    name= "CommercialForm"
                    setCommercialRegisterUrl={(url) => {
                        return updateCompanyPaper({ "commercial_register": url })
                        } 
                    }
                    // setCommercialRegisterUrl={(url) => updateCompanyPaper({ "commercial_register": url })}
                    
                    setTaxCardUrl={(url) => {
                        return updateCompanyPaper({ "tax_card": url })
                        } 
                    }
                    // setTaxCardUrl={(url) => updateCompanyPaper({ "tax_card": url })}
                    
                    setAddress={(text) => {
                        return updateCompanyPaper({ "address": text })
                        } 
                    }
                    // setAddress={(text) => updateCompanyPaper({ "address": text })}

                    showEmployeeDataForm={() => {
                        mixpanel.track('[sme v2] hit employees data form');
                        return setShowEmployeeData(true)
                        } 
                    }
                    // showEmployeeDataForm = {() => setShowEmployeeData(true)}
                    loader={companyPaperLoader}
                    setLoader={(val)=>setCompanyPaperLoader(val)}
                />
            }
            </div>
            <div className="EmployeeData">
            {showEmployeeData &&
                <EmployeeData 
                name="EmployeeData"
                id= "EmployeeData"
                onSubmit={(data) => submitEmployeeData(data)} 
                setRequestName={setRequestName}
                loader={submitEmployeeDataLoader}
                showModal={thanksModal}
                closeModal={()=>setThanksModal(false)}
                />
            }
            </div>
            {showPaymentForm &&
                <PaymentForm src={payLink} />
            }
            <BoldText>{t('Get health insurance for your employees through us in 5 simple steps')}</BoldText>
            <ol>
                <li>{t('Enter your company name and employee numbers then click the "calculate offers" button,')}<br />{t('enter your name and phone number then click the "go to offers" button')}</li>
                <li>{t("Compare between offers from different insurers and ask for help if you can't decide")}</li>
                <li>{t('Choose the most suitable plan then upload your commercial register, tax card and enter your current address')}</li>
                <li>{t('Enter your employees data (Name, Date of birth)')}</li>
                <li>{t("Add your payment data")}</li>
            </ol>
        </div>
    );
}

const BoldText = styled.div`
    font-size: 18px;
    font-weight: 600;
`;
const CenterText = styled.div`
    ${'' /* text-align: center; */}
    padding: 0 10px 0 0;
    ${'' /* margin: auto; */}
    width: 52%;
    font-size-adjust: 0.58;
`;
const Text = styled.div`
    font-size: 16px;
    font-weight: 400;
    padding: 0 20px 0 0
`;
const Input = styled.input`
    background-color: rgba(0,0,0,.05);
    &:focus {
        border: 2px solid red;
    };
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
    }
`;
const Button = styled.button`
 font-size: 16px;
 color: #FFF;
 border: 0.5px solid #515cdd;
 padding: 5px 15px;
 border-radius: 15px;
 outline: 0;
 margin: 10px 0px;
 cursor: pointer;
 transition: ease background-color 250ms;
 background: #515cdd;
`;