import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Container, Button } from "react-bootstrap";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spinner, Table } from "react-bootstrap";
export default function Dynamic(props) {
    const { t, i18n } = useTranslation();
    return (
        <Container className={ props.name }>
            {props.data.length > 0 &&
                <Row>
                    <BoldText style={{ padding: 25 }}>{t('2. Compare and Choose the most suitable plan')}</BoldText>
                    {props.data.map((item, k) => (
                        <Col key={k} xs={12} md={6} lg={4}>
                            <Card style={{    boxShadow: '1px 2px 9px #515cdd', marginBottom: 25 ,borderRadius: '40px'}}>
                                <Card.Img src={item.company.logo} style={{width: '50%', margin: 'auto'}} />
                                <Card.Body>
                                    <Table style={{borderCollapse: 'collapse'}}>
                                        <tbody>
                                            <tr>
                                                <Td><BoldText>{t('plan name:')}</BoldText></Td>
                                                <td><Text dangerouslySetInnerHTML={{ __html: item.plan_name }} /></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Life Coverage Ceiling:')}</BoldText></Td>
                                                <td><Text>{item.benefits.life_coverage_ceiling}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Health coverage ceiling:')}</BoldText></Td>
                                                <td><Text>{item.annual_ceiling}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Room & Board:')}</BoldText></Td>
                                                <td><Text>{item.benefits['room_&_board']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Inpatient services:')}</BoldText></Td>
                                                <td><Text>{item.benefits['impatient_services']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('outpartient services:')}</BoldText></Td>
                                                <td><Text>{item.benefits['outpatient_services']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Medications')}:</BoldText></Td>
                                                <td><Text>{item.benefits['medications']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Physiotherapy')}:</BoldText></Td>
                                                <td><Text>{item.benefits['physiotherapy']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Dental Coverage:')}</BoldText></Td>
                                                <td><Text>{item.benefits['dental_coverage']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Optical Coverage:')}</BoldText></Td>
                                                <td><Text>{item.benefits['optical_coverage']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Chronic & pre-existing:')}</BoldText></Td>
                                                <td><Text>{item.benefits['chronic_&_pre-existing_coverage']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Outside network service:')}</BoldText></Td>
                                                <td><Text>{item.benefits['outside_network_service']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Additional benefits:')}</BoldText></Td>
                                                <td><Text>{item.benefits['additional_benefits']}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Premium per member:')}</BoldText></Td>
                                                <td><Text>{item.average_premium}</Text></td>
                                            </tr>
                                            <tr>
                                                <Td><BoldText>{t('Total annual premiums:')}</BoldText></Td>
                                                <td><Text>{item.total_annual_premium}</Text></td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    {/* <Button
                                        size="small"           
                                        style={{
                                            display: document.body.dir = i18n.language == 'en' ? {margin: 10, borderRadius: 15,background:'#515cdd', 
                                            transform: "translateX(83%)"} :{ margin: 10,
                                            borderRadius: 15,
                                            background:'#515cdd', 
                                            transform: "translateX(-43%)" } }}              
                                        >
                                        Mark as Completed
                                    </Button> */}



                                    <Button 
                                        variant="primary" 

                                        // className={ i18n.language == 'en' ? "get-offer-btn get-offer-btn-en" : "get-offer-btn get-offer-btn-ar"}

                                        className = 'get-offer-btn'
                                        style={{
                                            borderRadius: 15,
                                            background: '#515cdd'
                                        }}          
                                        
                                        onClick={props.loader ? () => console.log('') : () => props.selectPlan(item)}>{props.loader ? <Spinner animation="border" /> : t('Get this offer')}</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            }
        </Container>
    )
}


const SubContainer = styled.div`
    display:flex;
    border-bottom: 2px solid #3f6bc2;
    padding:10px 7px 5px 7px;
`;
const BoldLine = styled.div`
    display:flex;
    width:100%;
    height:2px;
    background-color:#3f6bc2;
`;
const Td = styled.td`
    border-right-width: 1px;
`;
const BoldText = styled.div`
    font-size: 18px;
    font-weight: 600;
    padding: 0 20px 0 0
`;
const Text = styled.div`
    font-size: 20px;
    padding: 0 20px 0 0;
    color: #666666;
`;