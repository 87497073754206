import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from 'styled-components';
import { getFileExtention, convertToBase64, uploadToS3 } from '../Functions';
import { scroller } from 'react-scroll'
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
export default function CommercialForm(props) {
    const { t, i18n } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control
    } = useForm();
    const [chronicCondition, setChronicCondition] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [commercialRegister, setCommercialRegister] = useState(null);
    const [address, setAddress] = useState(null);
    const [taxCard, setTaxCard] = useState(null);
    const submitCompanyCertificate = async () => {
        props.setLoader(true)
        if(chronicCondition){
            setShowModal(true); 
        }else{
            if (commercialRegister) {
                let commfileName = commercialRegister ? commercialRegister.target.files[0].name : null;
                let commfileData = commercialRegister ? getFileExtention(commercialRegister.target.files[0].name) : null;
                let commbase64Text = commercialRegister ? await convertToBase64(commercialRegister.target.files[0]) : null;
                await uploadToS3(commbase64Text, commfileName, commfileData.extension)
                    .then(async function (data) {
                        props.setCommercialRegisterUrl(data.imageUrl)
                    }).catch((err) => console.log(err.message))
            }
            if (taxCard) {
                let cardfileName = taxCard.target.files[0].name;
                let cardfileData = getFileExtention(taxCard.target.files[0].name);
                let cardbase64Text = await convertToBase64(taxCard.target.files[0]);
                await uploadToS3(cardbase64Text, cardfileName, cardfileData.extension).then(async function (data) {
                    props.setTaxCardUrl(data.imageUrl);
                }).catch((err) => console.log('errr0r card'))
            }
            if(address)
            props.setAddress(address);
            props.showEmployeeDataForm()        
            scroller.scrollTo("EmployeeData", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
              });
        }
        props.setLoader(false)
    }
    return (
        <form onSubmit={handleSubmit(submitCompanyCertificate)} style={{ maxWidth: '70%' }} className={props.name}>
            <BoldText>{t('3. Upload your commercial register, tax card and enter your current address')}</BoldText>
            <section style={{ border: '1px solid rgba(0,0,0,.125)', padding: 20,borderRadius: 15,marginTop: 6 }}>
                <Text htmlFor="commercial_register" className="mb-3 mt-3">{t('Commercial register')}</Text>
                <Input
                    id="commercial_register"
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                    placeholder={t('Commercial register')}
                    style={{ backgroundColor: 'rgba(0,0,0,.05)' }}
                    onChange={(e) => setCommercialRegister(e)}
                    onBlur={()=>mixpanel.track('[sme v2] upload commercial register')}
                />
                {errors.commercial_register && <span role="alert">{errors.commercial_register.message}</span>}
                <Text htmlFor="tax_card" className="mb-3 mt-3">{t('Tax card')}</Text>
                <Input
                    id="tax_card"
                    type="file"
                    placeholder={t('Tax card')}
                    onChange={(e) => setTaxCard(e)}
                    onBlur={()=>mixpanel.track('[sme v2] upload tax card')}
                />
                {errors.tax_card && <span role="alert">{errors.tax_card.message}</span>}
                <Text htmlFor="address" className="mb-3 mt-3">{t('Current address')}</Text>
                <Input
                    id="address"
                    aria-invalid={errors.passward ? "true" : "false"}
                    type="text"
                    placeholder={t('Current address')}
                    onChange={(e) => setAddress(e.target.value)}
                    onBlur={()=>mixpanel.track('[sme v2] enter current address')}
                />
                {errors.address && <span role="alert">{errors.address.message}</span>}
                <div>
                    <Text className="mt-3">{t('Does any of the members suffer from chronic conditions ?')}</Text>
                    <RadioBlock>
                        <Item>
                            <RadioButton
                                type="radio"
                                name="radio"
                                value="yes"
                                checked={chronicCondition}
                                onChange={() => setChronicCondition(true)}
                            />
                            <Text style={{ marginLeft: 10 }}>{t('Yes')}</Text>
                        </Item>
                        <Item>
                            <RadioButton
                                type="radio"
                                name="radio"
                                value="no"
                                checked={!chronicCondition}
                                onChange={() => setChronicCondition(false)}
                            />
                            <Text style={{ marginLeft: 10 }}>{t('No')}</Text>
                        </Item>
                    </RadioBlock>
                </div>
                <div className="d-flex justify-content-center">
                    <Button type="submit">{props.loader?<Spinner animation="border" />:t('Next')}</Button>
                </div>

                <Modal
                    show={showModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => setShowModal(false)}
                    size="lg"
                >
                    <Modal.Body>
                        <SubContainer>
                            <Text>{t('Due to having one member or more with chronic conditions, our team will get in touch shortly to support with the exact pricing')}</Text>
                        </SubContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShowModal(false)}>{t('Confirm')}</Button>
                        <Button onClick={() => setShowModal(false)}>{t('Back to results')}</Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </form>
    )
}

const SubContainer = styled.div`
    padding:10px 7px 5px 7px;
`;
const BoldLine = styled.div`
    display:flex;
    width:100%;
    height:2px;
    background-color:#3f6bc2;
`;
const BoldText = styled.div`
    font-size: 18px;
    font-weight: 600;
    padding:25px
`;
const Text = styled.div`
    font-size: 20px;
    padding: 0 20px 0 0;
    color: #666666;
`;
const RadioBlock = styled.div`
    display:flex;
    margin-top:20px;
`;

const Item = styled.div`
    display:flex;
    flex:1;
`;
const RadioButton = styled.input`
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 10px;
  &:hover {
    background: #ccc;
  }
  &:checked {
    background: rgb(81, 92, 221);
    border: 2px solid yellowgreen;
  }
`;
const Button = styled.button`
 font-size: 16px;
 color: #FFF;
 border: 0.5px solid #515cdd;
 padding: 5px 15px;
 border-radius: 15px;
 outline: 0;
 margin: 10px 0px;
 cursor: pointer;
 transition: ease background-color 250ms;
 background: #515cdd;
`;
const Input = styled.input`
    background-color: rgba(0,0,0,.05);
    
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
    }
`;