import axios from "axios";
import ApisFile from "./ApisFile";
export const getFileExtention = (filename) => {
    let arr = filename.split(".");
    let extension = arr.pop();
    return { extension, name: arr[0] };
}

const splitString = (str, split) => {
    let arr = str.split(split);
    return arr[1];
}

export const convertToBase64 = async (filename) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(splitString(reader.result, ';base64,'));
        }
        reader.readAsDataURL(filename);
        reader.onerror = (error) => {
            reject(error);
        }
    })
}

export const uploadToS3 = async (text, fileName, extension) => {
    let response = await axios.post(`${ApisFile.uploadImage}`, {
        image: text,
        name: fileName,
        contentType: extension,
        category: 'sme'
    })
    return response.data
}

export const createPaymentPage = async (leadId) => {
    let data = {
        "profile_id": "88724",
        "tran_type": "sale",
        "tran_class": "ecom",
        "framed": true,
        "framed_return_top": true,
        "cart_id": `amanleek_${leadId}`,
        "cart_currency": "EGP",
        "cart_amount": 2000,
        "cart_description": "Payment for id: 2345",
        "paypage_lang": "en",
        "callback": "https://amanleek.com/wp-json/wl/v2/payment/paytabs_callback",
        "return": "https://amanleek.com/post-payment"
    };
    let headers = {
        "Content-Type": "application/json",
        Authorization: "S6JNBMMRWM-J2ZM2BGRWL-26GHKWTMKH"
    };
    let url = 'https://secure-egypt.paytabs.com/payment/request' //"https://3s6oqeuja2.execute-api.eu-west-3.amazonaws.com/dev/payment/sme"
    
    return await axios.post(url, {id:"qwertydssd", amount: 30000}, { headers }).then((paydata) => {
    }).catch(err => console.log(err.message))
}

export const createRefreshToken = async (phoneNumber, name) => {
    let phone = phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`;
    let response = await axios.post(`${ApisFile.createAuthUser}`, {
        "name": name,
        "phone": phone
    }
    ).catch((err) => console.log(err.message));
    return response.data
}

export const createLead = async (userToken, name, phoneNumber) => {
    let phone = phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`;
    let response = await axios.post(`${ApisFile.leadUrl}`, {
        "name": name,
        "phone": phone
    }
        , { headers: { "Content-Type": "application/json", "Authorization": `${userToken.TokenType} ${userToken.IdToken}` }, }
    ).catch((err) => console.log(err.message));
    return response.data
};

export const requestRefreshToken = async (refreshToken) => {
    let response = await axios.post(`${ApisFile.refreshToken}`, {
        "refresh_token": refreshToken
    }
    )
        .catch((err) => console.log(err.message));
    return response.data;
};

export const handleSmeRequest = async (userToken, data, leadId, requestId) => {
    let response = await axios.post(`${ApisFile.smeRequestUrl}`, {
        data,
        "lead_id": leadId,
        "request_id": requestId
    }, {
        headers: { "Content-Type": "application/json", "Authorization": `${userToken.TokenType} ${userToken.IdToken}` },
    }).catch((err) => console.log(err.message));
    return response.data
};